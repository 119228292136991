<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->
    <b-alert variant="danger">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link class="alert-link"> Shop </b-link>
        for other items.
      </div>
    </b-alert>
    <div class="d-flex flex-column flex-sm-row pt-1 mb-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0 ml-auto"
      >
        <feather-icon icon="ShoppingCartIcon" class="mr-50" />
        <span>Convert Cart to order</span>
      </b-button>
    </div>
    <!-- Content -->
    <Loader v-if="loading" />
    <b-card v-else no-body>
      <b-card-body>
        <b-row>
          <b-col cols="2" md="1">
            <h3>Select</h3>
          </b-col>
          <b-col cols="12" md="5">
            <h3>Product Image</h3>
          </b-col>
          <b-col cols="12" md="6">
            <h3>Product Details</h3>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-body>
        <b-row class="my-2">
          <b-col cols="2" md="1" class="my-auto">
            <b-form-checkbox
              id="checkbox-1"
              v-model="status"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
              class="ml-3"
              style="background-color: red; padding: 1rem"
            >
            </b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                src="@/assets/images/banner/banner-1.jpg"
                alt="`Image of `"
                class="product-img"
                fluid
              />
            </div>
          </b-col>

          <b-col cols="12" md="6">
            <h4>{{ product ? product.title : '' }}</h4>

            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">
                Cart Total: ${{ product ? product.listPrice : '' }}
              </h4>
            </div>

            <b-card-text
              >In stock -
              <span class="text-success">{{
                product ? product.inStock : ''
              }}</span></b-card-text
            >

            <b-card-text
              >Description:
              {{ product ? product.description : '' }}</b-card-text
            >
            <hr />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BFormCheckbox,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { getCall } from '../Service';
import Loader from '../components/Loader.vue';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      cartItems: [],
      productId: '',
      product: [],
      loading: false,
    };
  },
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    Loader,
    BFormCheckbox,
  },
  async mounted() {
    await this.fetchCartDetails();
    await this.getProduct();
  },
  methods: {
    async fetchCartDetails() {
      this.loading = true;
      try {
        const { data } = await getCall(`admin/store/cart/${this.$route.params.id}`);
        this.cartItems = data.cartItem;
        this.productId = data.cartItem.productid;
      } catch (error) {
        //console.log('Error:', error);
      } finally {
        this.loading = false;
      }
    },
    async getProduct() {
      this.loading = true;
      try {
        const { data } = await getCall(`admin/store/product/${this.productId}`);
        this.product = data.data;
      } catch (error) {
        if (error.response) {
          Swal.fire('Error', error.response.data.message, 'error');
        } else {
          Swal.fire('Error', error, 'error');
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
